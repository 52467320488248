<script>
import Slider from "@vueform/slider";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Range Slider",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Range Slider",
      items: [{
        text: "Forms",
        href: "/",
      },
      {
        text: "Range Slider",
        active: true,
      },
      ],
      simpleValue: 10,
      sliderCustomzie: 300,
      sliderWithLabel: {
        value: 45,
        data: [15, 30, 45, 60, 75, 90, 120],
        range: [{
          label: "15 mins",
        },
        {
          label: "30 mins",
          isHide: true,
        },
        {
          label: "45 mins",
        },
        {
          label: "1 hr",
          isHide: true,
        },
        {
          label: "1 hr 15 mins",
        },
        {
          label: "1 hr 30 mins",
          isHide: true,
        },
        {
          label: "2 hrs",
        },
        ],
        rangeValue: {},
      },
      value: [20, 30, 40],
      merge: 10,
      format: {
        prefix: "$",
        decimals: 2,
      },
      loader: null,
      loadingValue: 0,
      customStyle: 50,
      stepData: 10,
      rtlvalue: 50,
      orientation: "vertical",
      direction: "rtl",
    };
  },
  components: {
    Slider,
    Layout,
    PageHeader,
  },
  methods: {
    /**
     * Range and label slider set range
     */
    callbackRange(val) {
      this.sliderWithLabel.rangeValue = val;
    },
    /**
     * Loading slider
     */
    startLoad() {
      this.loader = setInterval(() => {
        this.loadingValue++;
        if (this.loadingValue === 100) {
          clearInterval(this.loader);
        }
      }, 100);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-card-title>Range slider</b-card-title>
            <p class="card-title-desc">
              Cool, comfortable, responsive and easily customizable range slider
            </p>
            <b-row>
              <b-col md="6">
                <div class="p-3">
                  <h5 class="font-size-14 mb-5 mt-0">Default</h5>
                  <div class="p-3">
                    <Slider v-model="simpleValue" />
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="p-3">
                  <h5 class="font-size-14 mb-5 mt-0">Min-Max</h5>
                  <div class="p-3">
                    <Slider v-model="sliderCustomzie" :min="100" :max="500" />
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div class="p-3">
                  <h5 class="font-size-14 mb-5 mt-0">Range and Label</h5>
                  <div class="p-3">
                    <Slider v-model="sliderWithLabel.value" :data="sliderWithLabel.data" :range="sliderWithLabel.range"
                      @callbackRange="callbackRange" />
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="p-3">
                  <h5 class="font-size-14 mb-5 mt-0">Loading</h5>
                  <div class="p-3">
                    <Slider v-model="loadingValue" :is-disabled="true" class="slider-red" />
                    <br />
                    <b-button size="sm" variant="light" @click="startLoad">Start</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div class="p-3">
                  <h5 class="font-size-14 mb-4 mt-0">Rtl</h5>
                  <div class="p-3">
                    <Slider v-model="rtlvalue" :direction="direction" :orientation="orientation" />
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="p-3">
                  <h5 class="font-size-14 mb-5 mt-0">Format</h5>
                  <div class="p-3">
                    <Slider v-model="value" :merge="merge" :format="format" />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>